import { useEffect } from 'react';

export const useResizer = (sendMessage: (message: any) => void) => {
    useEffect(() => {
        const sendHeight = () => {
            const height = document.documentElement.offsetHeight;
            sendMessage({ height });
        };

        // Tell initial height to the parent
        sendHeight();

        window.addEventListener('resize', sendHeight, false);
        return () => window.removeEventListener('resize', sendHeight, true);
    }, [sendMessage]);
};
